.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4b4f54;
  margin-top: 80px;
  .header {
    font-family: "LSHelveticaNeueLight";
    font-size: 30px;
    margin-bottom: 8px;
  }
  .subheader {
    font-family: "LSHelveticaNeueLight";
    color: rgba(35, 31, 32, 0.5);
    font-size: 20px;
    margin-bottom: 52px;
  }
  .guidance {
    font-size: 14px;
    color: rgba(35, 31, 32, 0.5);
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 346px;
    .error {
      font-size: 12px;
      color: #b10004;
      align-self: flex-start;
      margin: 10px 0 0 20px;
    }
    input {
      width: 325px;
      height: 30px;
      border: 0;
      outline: 0;
      border-bottom: 2px solid #e3e3e3;
      padding-left: 20px;
      &::placeholder {
        color: rgba(35, 31, 32, 0.5);
      }
    }
    button {
      width: 155px;
      height: 50px;
      color: #fff;
      background-color: rgb(84, 76, 212);
      border-radius: 5px;
      border: 0;
      margin: 48px 0 12px 0;
      cursor: pointer;
      &:disabled {
        background-color: rgba(84, 76, 212, 0.5);
        cursor: default;
      }
    }
  }
}

.labelContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  .labelPlaceholder {
    margin-top: 11px;
    padding-left: 20px;
    font-size: 10px;
    color: rgba(35, 31, 32, 0.5);
  }
}

.inputSpacing {
  margin-top: 25px;
}