.container {
  color: #4b4f54;
  font-size: 14px;
  padding: 48px 26px 40px;
  line-height: 1.5;
  flex: 1 1;
  h1 {
    font-size: 30px;
    font-family: "LSHelveticaNeueLight";
    font-weight: 300;
    text-align: center;
    margin: 0;
  }
  a {
    color: #00afaa;
  }
  i {
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    color: rgba(35, 31, 32, 0.5);
  }
  hr {
    border-style: none;
    border-bottom-style: solid;
    width: 90%;
    margin-top: 48px;
  }
  .bold {
    font-family: "LSHelveticaNeueBold";
  }
  .appDownload {
    margin-top: 41px;
    align-self: center;
  }
  .callLink {
    padding-left: 2px;
  }
  .alreadyHaveApp {
    align-self: center;
    margin-top: 25px;
  }
  .dialingOptions {
    display: flex;
    flex-direction: column;
    .optionRow {
      .option {
        align-self: center;
        margin-top: 36px;
        flex: 0 0 auto;
        display: flex;
        min-width: 70%;
        justify-content: center;
        .iconContainer {
          flex: 0 0 35px;
          color: #4b4f54;
          align-self: flex-start;
        }
        .infoContainer {
          margin-left: 28px;
          flex: 1 1;
          max-width: 245px;
          > :first-child {
            font-size: 20px;
            margin-bottom: 10px;
          }
          .label {
            margin-right: 5px;
          }
          .value {
            font-family: "LSHelveticaNeueBold";
          }
          .thirdParty {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .downloadLinks {
    margin: 35px 0 0 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    a {
      margin-right: 30px;
    } //.macApp {
    //  width: 105px;
    //}
    //.windows {
    //  width: 132px;
    //}
    //.macStore {
    //  width: 133px;
    //}
    //.google {
    //  width: 134px;
    //}
  }
  .icon {
    width: 23px;
    height: 26px;
  }
  .phone {
    width: 24px;
    height: 24px;
  }
  .meeting {
    width: 34px;
    height: 28px;
  }
  .laptop {
    width: 30px;
    height: 25px;
  }
  .lync {
    background-image: url(../../images/lync.png);
    margin-right: 10px;
    width: 25px;
    height: 26px;
  }
  .skype {
    background-image: url(../../images/skype.png);
    background-position: 0px 0px;
    width: 159px;
    height: 23px;
    background-size: 159px;
  }
}