.container{
  flex: 1 1 auto;
  .content{
    font-family: "LSHelveticaNeueLight";
    color: #4B4F54;
    padding: 0 24px;
    margin: 55px 0 40px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 101px;
      margin-bottom: 20px;
    }
    .header{
      font-size: 30px;
      margin-bottom: 8px;
    }
    .subHeader{
      font-size: 20px;
      color: rgba(35, 31, 32, 0.5);
      text-align: center;
    }
  }
}
