.container {
  flex: 1 1 auto;
  margin: 0 0 40px 0;
  > a {
    display: flex;
    font-family: "LSHelveticaNeueLight";
    margin-top: 90px;
    color: #00afaa;
    font-size: 20px;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    i {
      color: #00afaa;
      font-size: 14px;
    }

    span {
      margin: 0 10px 0 20px;
    }
  }
  .up {
    transform: rotate(180deg);
  }
  .iconMeeting {
    font-size: 22px;
  }
}