.container {
  flex: 1 1 auto;
  display: flex;
  min-height: calc(100vh - 40px);
  flex-direction: column;
  .content {
    font-family: "LSHelveticaNeue";
    font-size: 14px;
    color: #4B4F54;
    padding: 0 80px 40px 80px;
    flex: 1;
    @media screen and (max-width: 870px) {
      padding: 0 30px 40px 56px;
    }
    .header {
      font-family: "LSHelveticaNeueLight";
      font-size: 30px;
      text-align: center;
      margin-top: 48px;
    }
    .subTitle {
      font-family: "LSHelveticaNeueLight";
      font-size: 20px;
      color: rgba(35, 31, 32, 0.5);
      text-align: center;
      margin-top: 8px;
    }
    .disclaimer {
      font-family: "LSHelveticaNeueLight";
      font-size: 14px;
      color: rgba(35, 31, 32, 0.5);
      text-align: center;
      margin-top: 12px;
    }
    .searchRow {
      margin-top: 40px;
      display: flex;
      justify-content: flex-end;
      align-content: space-between;
      @media screen and (max-width: 870px) {
        justify-content: flex-start;
      }
      .searchBox {
        width: 186px;
        height: 18px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        padding: 6px 8px;
      }
    }

    .headerRow {
      font-family: "LSHelveticaNeueBold";
      display: flex;
      align-content: space-between;
      color: #231f20;
      padding: 12px 0;
      margin-top: 24px;
      span {
        flex: 1;
      }
    }
    .countryRow {
      display: flex;
      align-content: space-between;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding: 12px 0;
      color: #4B4F54;
      > span {
        flex: 1;
      }
      .number {
        color: #00afaa;
        flex: 1;
      }
    }
  }
}
