.container {
  display: flex;
  height: 40px;
  background-color: #290088;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  flex: 0 0 auto;
  width: 100%;
  .logo {
    width: 108px;
    height: 22px;
    display: block;
    padding-left: 15px;
  }
  i {
    color: #fff;
    font-size: 24px;
  }
  .rightSide {
    display: flex;
    align-items: center;
    height: 40px;
    .question {
      height: 20px;
      width: 20px;
      margin: 10px 24px;
    }
  }
}