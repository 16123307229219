html,
body,
body>div {
  height: 100%;
  width: 100%;
  font-family: "LSHelveticaNeue";
  overflow: auto;
  overflow: initial;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "LSHelveticaNeue";
  src: url('../fonts/HelveticaNeue/HelveticaNeue.eot');
  src: url('../fonts/HelveticaNeue/HelveticaNeue.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeue/HelveticaNeue.woff') format('woff'), url('../fonts/HelveticaNeue/HelveticaNeue.ttf') format('truetype'), url('../fonts/HelveticaNeue/HelveticaNeue.svg') format('svg');
}

@font-face {
  font-family: "LSHelveticaNeueLight";
  src: url('../fonts/HelveticaNeue/HelveticaNeue-Light.eot');
  src: url('../fonts/HelveticaNeue/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeue/HelveticaNeue-Light.woff') format('woff'), url('../fonts/HelveticaNeue/HelveticaNeue-Light.ttf') format('truetype'), url('../fonts/HelveticaNeue/HelveticaNeue-Light.svg') format('svg');
}

@font-face {
  font-family: "LSHelveticaNeueMedium";
  src: url('../fonts/HelveticaNeue/HelveticaNeue-Medium.eot');
  src: url('../fonts/HelveticaNeue/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeue/HelveticaNeue-Medium.woff') format('woff'), url('../fonts/HelveticaNeue/HelveticaNeue-Medium.ttf') format('truetype'), url('../fonts/HelveticaNeue/HelveticaNeue-Medium.svg') format('svg');
}

@font-face {
  font-family: "LSHelveticaNeueBold";
  src: url('../fonts/HelveticaNeue/HelveticaNeue-Bold.eot');
  src: url('../fonts/HelveticaNeue/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeue/HelveticaNeue-Bold.woff') format('woff'), url('../fonts/HelveticaNeue/HelveticaNeue-Bold.ttf') format('truetype'), url('../fonts/HelveticaNeue/HelveticaNeue-Bold.svg') format('svg');
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/fonts/icomoon.eot?p0m951');
  src: url('../fonts/icomoon/fonts/icomoon.eot?p0m951#iefix') format('embedded-opentype'), url('../fonts/icomoon/fonts/icomoon.ttf?p0m951') format('truetype'), url('../fonts/icomoon/fonts/icomoon.woff?p0m951') format('woff'), url('../fonts/icomoon/fonts/icomoon.svg?p0m951#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #4B4F54;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
