.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 10px;
  }
  .callMeContent {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .hasRTCContent {
    flex: 1 1 auto;
  }
}
