.container {
  color: #4b4f54;
  font-size: 14px;
  flex: 1 1;
  .header {
    font-size: 30px;
    font-family: "LSHelveticaNeueLight";
    text-align: center;
    padding: 48px 0 8px 0;
  }
  .subHeader {
    font-size: 20px;
    color: rgba(35, 31, 32, 0.5);
    font-family: "LSHelveticaNeueLight";
    text-align: center;
  }
  .contentFlexWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .subSection {
      margin-top: 48px;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: column;
      .subSectionHeader {
        font-size: 20px;
        font-family: "LSHelveticaNeueMedium";
      }
      .subSectionContent {
        font-family: "LSHelveticaNeueLight";
        margin: 8px 0 0;
        max-width: 571px;
      }
      .buttonContainer {
        display: flex;
        justify-content: center;
        align-self: center;
        margin-top: 25px;
        .logoSpacer {
          margin-top: 20px;
          display: flex;
        }
      }
    }
  }
  .macStore {
    //width: 133px;
    margin-top: 25px;
  }
  .google {
    //width: 134px;
    margin-top: 25px;
  }
  .macApp {
    //width: 105px;
    margin-right: 18px;
  }
  .windows {
    //width: 132px;
  }
  .browserLogo {
    background-size: contain;
    height: 60px;
    width: 60px;
    margin-right: 24px;
    background-repeat: no-repeat;

    &.chrome {
      background-image: url(~@lifesize/ux-assets/images/browsers/chrome.png);
    }
    &.ie {
      background-image: url(~@lifesize/ux-assets/images/browsers/ie.png);
    }
    &.edge {
      background-image: url(~@lifesize/ux-assets/images/browsers/edge.png);
    }
    &.firefox {
      background-image: url(~@lifesize/ux-assets/images/browsers/firefox.png);
    }
    &.safari {
      background-image: url(~@lifesize/ux-assets/images/browsers/safari.png);
    }
  }
  .line {
    width: 571px;
    height: 2px;
    border-top: solid 1px #979797;
    margin: 48px 0;
  }
}