.container {
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
  flex: 0 0 auto;
  .lifesizeDesktop {
    background-color: #00afaa;
    width: 183px;
    height: 40px;
    color: #FFF;
    display: flex;
    align-items: center;
    padding-left: 11px;
    cursor: pointer;
    .buddy {
      width: 30px;
    }
    .calloutText {
      font-size: 10px;
      margin-left: 8px;
    }
    .bold {
      font-family: "LSHelveticaNeueBold";
    }
    i {
      color: #fff;
      font-size: 26px;
    }
  }
}
