.iconChevron:before {
  content: "\e905";
}
.iconBuddy:before {
  content: "\e900";
}
.iconLsLogo:before {
  content: "\e901";
}
.iconLaptop:before {
  content: "\e902";
}
.iconMeeting:before {
  content: "\e903";
}
.iconAudioCallPstn:before {
  content: "\e904";
}
.iconContactProfile:before {
  content: "\e915";
}
.iconRoomSystem:before {
  content: "\e92a";
}
.iconHelpOutline:before {
  content: "\e93f";
}
