.container {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 15px;
  a{
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .arrowDown {
    margin-left: 5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
  }
  .dropdown {
    position: absolute;
    top: 20px;
    background: #fff;
    left: -70px;
    z-index: 10;
    border: 1px solid #ddd;
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,1);
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        width: 130px;
        height: 25px;
        border-bottom: 1px solid #ddd;
        //&:first-of-type {
        //  border-top: 1px solid #ddd;
        //  border-radius: 5px 5px 0 0;
        //}
        &:last-of-type {
          border-bottom: 0;
        }
        a {
          width: 100%;
          height: 100%;
          color: #4b4f54;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: #ddd;
          }
        }
      }
    }
  }
}
