.container {
  color: #4b4f54;
  font-size: 14px;
  flex: 1 1;
  padding-bottom: 40px;
  .header {
    font-size: 30px;
    font-family: "LSHelveticaNeueLight";
    padding: 48px 0 8px 0;
    text-align: center;
  }
  .subHeader {
    font-size: 20px;
    color: rgba(35, 31, 32, 0.5);
    font-family: "LSHelveticaNeueLight";
    text-align: center;
  }
  .subSection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 48px;
    padding: 0 20px;
    .subSectionLeft {
      display: flex;
      flex: 0 1 448px;
      flex-direction: column;
      @media screen and (max-width: 870px) {
        flex: 0 1 330px;
      }
    }
    .subSectionRight {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 300px;
      margin-left: 65px;
      &.browserLogos {
        flex-direction: column;
        align-items: flex-start;
      }
      .logoContainer {
        display: flex;
      }
      .logoSpacer {
        margin-top: 40px;
        display: flex;
      }
    }
    .subSectionHeader {
      color: #231f20;
      font-size: 20px;
      font-family: "LSHelveticaNeueMedium";
    }
    .subSectionContent {
      color: #4b4f54;
      margin-top: 8px;
      .spacer {
        margin-top: 50px;
      }
    }
    .subSectionImage {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 5px;
    }
    .subSectionLeftLower {
      display: flex;
      flex: 0 0 330px;
      flex-direction: column;
      justify-content: flex-start;
    }
    .subSectionRightLower {
      display: flex;
      flex: 0 1 466px;
      align-items: center;
      margin: 48px 0 0 16px;
    }
  }

  .chrome {

    background-size: contain;
    height: 60px;
    width: 60px;
    background-repeat: no-repeat;
  }

  .browserLogo {
    background-size: contain;
    height: 60px;
    width: 60px;
    margin-right: 24px;
    background-repeat: no-repeat;

    &.chrome {
      background-image: url(~@lifesize/ux-assets/images/browsers/chrome.png);
    }
    &.ie {
      background-image: url(~@lifesize/ux-assets/images/browsers/ie.png);
    }
    &.edge {
      background-image: url(~@lifesize/ux-assets/images/browsers/edge.png);
    }
    &.firefox {
      background-image: url(~@lifesize/ux-assets/images/browsers/firefox.png);
    }
    &.safari {
      background-image: url(~@lifesize/ux-assets/images/browsers/safari.png);
    }
  }
  .chromeStore {
    background-image: url(../../images/chrome-store.png);
    height: 60px;
    width: 198px;
  }
  .outlook {
    background: url(../../images/outlook.png);
    background-repeat: no-repeat;
    background-size: 198px 31px;
    height: 31px;
    width: 198px;
  }
  .gSuite {
    background-image: url(../../images/g-suite.png);
    background-repeat: no-repeat;
    background-size: 158px 40px;
    height: 40px;
    width: 158px;
  }
  .googleWorkspace {
    background-image: url(../../images/googleWorkspace.png);
    background-repeat: no-repeat;
    background-size: 198px 26px;
    height: 26px;
    width: 198px;
  }
  .macApp {
    //width: 105px;
    margin-right: 18px;
  }
  .windows {
    //width: 132px;
  }
  .macStore {
    //width: 133px;
    margin-right: 18px;
  }
  .google {
    //width: 134px;
  }
  .line {
    width: 600px;
    height: 2px;
    border-top: solid 1px #979797;
    margin: 48px auto;
    @media screen and (max-width: 870px) {
      width: 675px;
    }
  }
}
